import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeSnippet = ({ code, language }) => {
  return (
    <div style={{ borderRadius: '8px', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
      <div
        style={{
          color: '#d4d4d4',
          padding: '8px 16px',
          fontFamily: 'Consolas, "Courier New", monospace',
          fontSize: '14px',
        }}
      >
      </div>
      <SyntaxHighlighter language={language} style={dracula} showLineNumbers>
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeSnippet;
