export const exampleCode = `
import React, { useState } from 'react';

export const Demo = () => {
  // ❌ Necesitamos crear un estado para manejar los datos del formulario
  const [fullName, setFullName] = useState ('')   
}

  // ❌ Necesitamos crear una función para manejar el envío del formulario
const handleSubmit = async (e) => {
  e.preventDefault()
  await fetch('https://api.example.com/submit', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ fullName })
  })
}

  return (
      <form onSubmit={handleSubmit}> {/* ❌ Necesitamos pasar la funcion al evento onSubmit*/}
          <input 
              type="text" 
              value={fullName} 
              onChange={(e) => setFullName(e.target.value)} 
          />
          <button type="submit">Enviar</button>
      </form>
  )
};
`;

export const exampleCodeFetch = `
import React from 'react';

export const Demo = () => {
  // ✅ Ahora podemos pasar los datos del formulario directamente a la acción del formulario

  async function myFormAction(formData) {
      await fetch('https://api.example.com/submit', {
          method: 'POST',
          body: formData
      })
  }

  return (
      <form action={myFormAction}> {/* ✅ Ahora usando el atributo action del formulario*/}
          <input 
              type="text" 
              value={fullName} 
              onChange={(e) => setFullName(e.target.value)} 
          />
          <button type="submit">Enviar</button>
      </form>
  )
};
`;

export const formCodeServer = `
  'use server'
  export async function myFormAction(formData) {
      // ✅ Podemos ejecutar cualqueir lógica del servidor con los datos del formulario
      const sql = 'INSERT INTO users (name) VALUES ($formData.fullName)';
  }

  // ✅ Podemos importar la accion del formuario en el lado del cliente --> Demo.jsx
  'use client'
  import React from 'react';
  import { myFormAction } from './formCodeServer';

  export const Demo = () => {
      return (
          <form action={myFormAction}>
              <input type="text" name="fullName" />
              <button type="submit">Enviar</button>
          </form>
      )
  }
`

export const formCodeServer2 = `
  'use client'
  import React, { useActionState } from 'react';
  import { myFormAction } from './formCodeServer';

  // ✅ Definimos el valor inicial del estado de la aciión del formulario
  const initialState = {
      message: '
  };

  export const Demo = () => {
  // ✅ Usamos el hook useActionState para observar el estado de la acción del formulario
  const [state, formAction, pending] = useActionState(myFormAction, initialState);
  
  return (
      <form action={formAction}>
          <input 
              type="text" 
              name="fullName" 
          />
          <button 
              aria-disabled={pending}    
              type="submit"
              {pending ? 'Enviando...' : 'Enviar'} {/* ✅ Tenenos acceso al estado de la acción del formulario */}
          >
          </button>
          {state?.message && <p>{state.message}</p>} {/* ✅ Mostramos el mensaje de la acción del formulario */}
      </form>
  )
  }
`

export const useHookState = `
  import React, { useState } from 'react';

  function UserProfile() {
      const [profile, setProfile] = useState({
          name: '',
          email: '',
          city: {
              name: '',
              zipCode: ''
          }
      });
  }

  const handleUpdateCity = (city) => {
      // ❌ Si bien esto funciona, no es la mejor forma de actualizar el esatdo anidado
      setProfile((prevProfile) => ({
          ...profile,
          city : {
              ...profile.city,
              name: city
          }
      }));
  }

  return (
      <div>
          <h1>{profile.name}</h1>
          <p>{profile.email}</p>
          <p>{profile.city.name}</p>
          <button onClick={handleUpdateCity('New York')}>Update City</button>
      </div>
  )
`

export const useHookState2 = `
  import React, { useReducer } from 'react';

  // -> Definimos el estado inicial
  const initialState = {
      name: '',
      email: '',
      city: {
          name: '',
          zipCode: ''
      }
  };

  const reducer = (state, action) => {
      switch (action.type) {
          case 'UPDATE_CITY':
              return { ...state, address: { ...state.address, city: action.payload } };
          default:
              return state;
      }
  };

  function UserProfile() {
      // ✅ Usamos el hook useReducer para manejar el estado complejo
      const [state, dispatch] = useReducer(reducer, initialState);

      const handleUpdateCity = (city) => {
          // ✅ Actualizamos el estado de forma mas sencilla
          dispatch({ type: 'UPDATE_CITY', payload: 'New York' });
      }

      return (
          <div>
              <h1>{state.name}</h1>
              <p>{state.email}</p>
              <p>{state.city.name}</p>
              <button onClick={handleUpdateCity}>Update City</button>
          </div>
      )
  }
`

export const useBundleComponent = `
  import React from 'react';

  import { MyComponent1 } from './MyComponent';
  import { MyComponent2 } from './MyComponent';
  import { MyComponent3 } from './MyComponent';
  import { MyComponent4 } from './MyComponent';

  function App() {
      return (
          <>
              <MyComponent1 />
              <MyComponent2 />
              <MyComponent3 />
              <MyComponent4 />
          </>
      );
  }
`

export const useBundleComponent2 = `
  import React, { Suspense, lazy } from 'react';

  const MyComponent1 = lazy(() => import('./MyComponent'));
  const MyComponent2 = lazy(() => import('./MyComponent'));
  const MyComponent3 = lazy(() => import('./MyComponent'));
  const MyComponent4 = lazy(() => import('./MyComponent'));

  function App() {
      return (
          <>
              <Suspense fallback={<div>Loading...</div>}>
                  <MyComponent1 />
                  <MyComponent2 />
                  <MyComponent3 />
                  <MyComponent4 />
              </Suspense>
          </>
      );
  }
`

export const useEffectComponent = `
  import React, { useEffect } from 'react';

  function Input() {
      useEffect(() => {
      // ❌ Esto es una mala practica, ya que estamos manipulando el DOM directamente
          const input = document.getElementById('input');
          input.focus();
      }, []);
  }

  return (
      <input id="input" type="text" placeholder="Escribe algo..." />
  )
`

export const useEffectComponent2 = `
  import React, { useEffect, useRef } from 'react';

  function Input() {
    // ✅ Usamos el hook useRef para referenciar el input
      const inputRef = useRef(null);

      useEffect(() => {
      // ✅ Verificamos si inputRef.current existe
          if (inputRef.current) return;
          
          // ✅ Usamos inputRef.current para manipular el DOM
          inputRef.current.focus();
      }, []);
  }

  return (
      <input type="text" placeholder="Escribe algo..." ref={inputRef} />
  )
`
export const styleCode = `
  import React from 'react';

  function MyComponent() {
      return (
        // ❌ Esto es una mala practica, ya que estamos definiendo estilos en línea
          <button style={{ color: 'red' }}>Click me!</button>
      );
  }
`

export const styleCode2 = `
  import React from 'react';

  import { styles } from './styles';

  function MyComponent() {
      return (
          <div className={styles.container}>
              <h1 className={styles.title}>Hola Mundo</h1>
          </div>
      );
  }
`

export const mapCode = `
  const FRUITS = ['apple', 'banana', 'cherry'];

  function MyComponent() {
      return (
          <ul>
              {FRUITS.map((fruit) => (
                  {/* ❌ Mala practica no asignar keys unicas */}
                  <li key={fruit}>{fruit}</li>
              ))}
          </ul>
      );
  }
`

export const mapCode2 = `
  const FRUITS = ['apple', 'banana', 'cherry'];

  function MyComponent() {
      return (
          <ul>
              {FRUITS.map((fruit) => (
                  {/* ✅ Asignamos una key unica a cada elemento de la lista */}
                  <li key={fruit}>{fruit}</li>
              ))}
          </ul>
      );
  }
`

export const componentBigCode = `
  // ❌ Esto es una mala practica, ya que estamos definiendo un componente muy grande
  function MyComponent( { props } ) {
      return (
          <div>
              <h1>{props.title}</h1>
              <p>{props.description}</p>
              <button onClick={props.onClick}>Click me!</button>
              <ul>
                  {props.items.map((item) => (
                      <li key={item.id}>{item.name}</li>
                  ))}
              </ul>
          </div>
      );
  }
`

export const componentBigCode2 = `
  function UserInfo( { user } ) {
      return (
          <div>
              <h1>{user.name}</h1>
              <p>{user.email}</p>
              <button onClick={() => alert('Followed!')}>Follow</button>
          </div>
      );
  }

  function UserProfile( { user } ) {
      return (
          <ul>
              {props.items.map((item) => (
                  <li key={item.id}>{item.name}</li>
              ))}
          </ul>
      );
  }

  function MyComponent() {
      return (
          <div>
              <UserInfo user={user} />
              <UserProfile user={user} />
          </div>
      );
  }
`

export const ternaryCode = `
  function Message( { type } ) {
      return (
          <div>
              <h1>{type === 'success' 
              ? 'Hello' 
              : type === 'error' 
              ? 'Goodbye' 
              : 'Unknown'}
              </h1>
          </div>
      );
  }
`

export const useTernaryCode = `
  function Message( { type } ) {

  // Nota: si no depende de las props o del estaso se puede declarar afuera
  const getMessage = () => {
      const messages = {
        success: 'Hello',
        error: 'Goodbye',
        default: 'Unknon message'
      }
      return messages[type] || messages.default
  }

  return (
      <div>
          <h1>{getMessage()}</h1>
      </div>
  )
`

export const inlineCode = `
  // ❌ Mala practica usar funciones inline en nuestro jsx
  function UserInfo( { user } ) {
      return (
          <>
              <h1>{user.name}</h1>
              <p>{user.email}</p>
              <button onClick={() => alert('Followed!')}>Follow</button>
          </>
      );
  }
`

export const inlineCode2 = `
  function UserInfo( { user } ) {
    const handleFollow = () => {
      alert('Followed!')
    }
  
  return (
          <div>
              <h1>{user.name}</h1>
              <p>{user.email}</p>
              <button onClick={handleFollow}>Follow</button>
          </div>
      );
  }
`

export const logicComponentCode = `
  function MyComponent( { products } ) {
      return (
          <div>
              {products.length > 0 ? (
                  <ul>
                      {products.map((product) => (
                          <li key={product.id}>{product.name}</li>
                      ))}
                  </ul>
              ) : (
                  <p>No hay productos</p>
              )}
          </div>
      );
  }
`
export const logicComponentCode2 = `
  function ProductList( { products } ) {
  if (products.length === 0) return <div>No hay productos</div>;

  return (
          <div>
              <ul>
                  {products.map((product) => (
                      <li key={product.id}>{product.name}</li>
                  ))}
              </ul>
          </div>
      );
  }
`

export const mutateStateCode = `
import React from 'react';

function Counter() {
  const [count, setCount] = React.useState(0);
 
  function increment() {
    count++; // ❌ Mala practica, ya que estamos mutando el estado directamente
    setCount(count); // ❌ Actualizando el estado, pero no funciona
 }
  return (
      <div>
          <p>Count: {count}</p>
          <button onClick={increment}>Increment</button>
      </div>
  );
}
`

export const mutateStateCode2 = `
  import React from 'react';
// ✅ Codigo sin mutar el estado directamente

  function Counter() {
      const [count, setCount] = React.useState(0);
      function increment() {
          setCount(count + 1);
      }
  }
      return (
          <div>
              <p>Count: {count}</p>
              <button onClick={increment}>Increment</button>
          </div>
      );
  }
`

export const useStateExcessCode = `
  import React, { useState } from 'react';
 // ❌ Codigo con mucho uso de useState, dificil de leer y mantener
  function UserForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
    return (
      <form>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
      </form>
    )
  }
`

export const useStateExcessCode2 = `
  import React, { useState } from 'react';

  // ✅ Codigo agrupando los estados relacionados en un solo objecto
  function UserForm() {
      const [form, setForm] = useState({
          name: '',
          email: '',
          phone: ''
      });
  }

  function handleChange(e) {
      setForm({ 
          ...form, 
          [e.target.name]: e.target.value 
      });
  }

  return (
      <form>
          <input type="text" name="name" value={form.name} onChange={handleChange} />
          <input type="email" name="email" value={form.email} onChange={handleChange} />
          <input type="tel" name="phone" value={form.phone} onChange={handleChange} />
      </form>
  )
`

export const logicErrorCode = `
import React, { useState, useEffect } from 'react';
 // ❌ Codigo con logica repetida en el componete Post y Comment
 function Post() {
 const [post, setPost] = useState({});

 useEffect(() => {
    fetch('https://api.example.com/post')
    .then(response => response.json())
    .then(data => setPost(data))
    .catch(error => console.error(error));
  }, []);

  return (
      <div>
          <h1>{post.length}</h1>
      </div>
  );
}

function Comment() {
  const [comment, setComment] = useState({});

  useEffect(() => {
    fetch('https://api.example.com/comment')
    .then(response => response.json())
    .then(data => setComment(data))
    .catch(error => console.error(error));
  }, []);

  return (
      <div>
          <h1>{comment.length}</h1>
      </div>
  );
}
`

export const logicCodeHook = `
  import React, { useState, useEffect } from 'react';

  // ✅ Extracción de la lógica de fetch en un hook personalizado
  function useFetch(url) {
    const [data, setData] = useState(null);

    useEffect(() => {
      fetch(url)
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error(error));
    }, []);

    return data;
  }

  function Post() {
    const post = useFetch('https://api.example.com/post');
    return <div>{post.length}</div>;
  }

  function Comment() {
    const comment = useFetch('https://api.example.com/comment');
    return <div>{comment.length}</div>;
  }
`

export const noFunctionComponent = `
  function MyComponent() {
    // ❌ Mala practica, ya que estamos definiendo una función dentro del componente
    const handleClick = () => {
      alert('Clicked!');
    }

    return (
      <button onClick={handleClick}>Click me!</button>
    );
  }
`

export const noFunctionComponent2 = `
  const handleClick = () => {
    alert('Clicked!');
  }

  function MyComponent( { onClick } ) {
    return (
      <button onClick={handleClick}>Click me!</button>
    );
  }
`

export const defineConstantCode = `
  function MyComponent() {
    const name = 'John';
    return (
      <div>
        <h1>{name}</h1>
      </div>
    );
  }
`

export const defineConstantCode2 = `
  const name = 'John';

  function MyComponent() {
    return (
      <div>
        <h1>{name}</h1>
      </div>
    );
  }
`

export const cleanEffectCode = `
  import React, { useEffect } from 'react';

  function MyComponent() {
    useEffect(() => {
      // ❌ Mala practica, ya que no estamos limpiando el efecto secundario
      return () => {
        console.log('Component unmounted');
      }
    }, []);
  return (
    <div>
      <h1>My Component</h1>
    </div>
  )
  }
`

export const cleanEffectCode2 = `
  import React, { useEffect } from 'react';

  function MyComponent() {
    useEffect(() => {
      // ✅ Limpiamos el efecto secundario al desmontar el componente
      const interval = setInterval(() => {
        console.log('Component mounted');
      }, 1000);

      return () => clearInterval(interval); // ✅ Limpiamos con clearInterval
    }, []);
  }
`

export const elementInnecesarioCode = `
  function MyComponent() {
    return (
      <div> {/* ❌ Mala practica, ya que estamos creando un div innecesario */}
        <h1>My Component</h1>
      </div>
    );
  }
`

export const elementInnecesarioCode2 = `
  function MyComponent() {
    return (
      <>
        <h1>My Component</h1>
      </>
    );
  }
`