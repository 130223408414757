export const arrayBlog = [
    {
        title: "Manejo de formularios en React",
        description: "Sin dudas React es una de las librerías más populares para el desarrollo de interfaces de usuario. Su evolución ha sido siempre en la dirección de simplificar el desarrollo de aplicaciones web, algo que ha logrado con creces. Veamos un ejemplo de una de las nuevas características que ha introducido React en su versión 10, se relaciona con el manejo de formularios. Veamos cómo se hacía en versiones anteriores"
    },
    {
        title: "",
        description: "Veamos cómo se simplifica el manejo de formularios en React 19 gracias a la introducción de los actions (form actions) que permiten manejar los formularios de una manera mucho más sencilla, veamos un ejemplo del lado del cliente"
    },
    {
        title: "",
        description: "Referentemente a la simplificación de los formularios en React 19, podemos hacer que la acción de manejo del formulario se ejecute en el servidor, logrando una mejor separación de responsabilidades como en el siguiente ejemplo"
    },
    {
        title: "",
        description: "En esta nueva versión se ha introducido un nuevo hook useActionState, que nos permite observar el estado de la acción del formulario en el servidor desde el lado del cliente, veamos un ejemplo"
    },
    {
        title: "Manejo de Hook State",
        description: "Una mala práctica en la que se suele caer al trabajar con React es la de manejar un estado complejo con el hook useState. Si bien esto no es incorrecto, puede llevar a un código poco mantenible y difícil de leer. Veamos un ejemplo"
    },
    {
        title: "",
        description: "Lo que podemos hacer para evitar caer en lo anterior es usar el hook useReducer, que nos permite manejar estados más complejos de una forma más sencilla y mantenible, veamos un ejemplo"
    },
    {
        title: "",
        description: "Otra mala práctica en la que solemos incurrir al trabajar con React es cargar todos los componentes de nuestra aplicación sin importar si el usuario los necesita o no. Esto puede ser un problema si nuestra aplicación es muy grande y tiene muchos componentes, ya que esto puede afectar el rendimiento de nuestra aplicación y termina afectando el tamaño de nuestro bundle. Vamos a ver un ejemplo de esto"
    },
    {
        title: "",
        description: "Lo que podemos hacer es usar Lazy Loading, que nos permite cargar los componentes de manera lazy, es decir, solo cuando el usuario los necesita. Esto puede ser útil si nuestra aplicación es muy grande y tiene muchos componentes, ya que esto puede mejorar el rendimiento de nuestra aplicación y el tamaño de nuestro bundle, veamos un ejemplo"
    },
    {
        title: "Manipulación del DOM con sus componentes",
        description: "Una mala práctica que solemos cometer es manipular el DOM directamente en el componente, esto puede ser un problema si nuestra aplicación es muy grande y tiene muchos componentes, ya que esto puede afectar el rendimiento de nuestra aplicación y termina afectando el tamaño de nuestro bundle. Vamos a ver un ejemplo de esto"
    },
    {
        title: "",
        description: "Lo que podemos hacer es usar el hook useEffect, que nos permite ejecutar un efecto secundario cuando el componente se monta, esto nos permite manipular el DOM de una manera más segura y mantenible, veamos un ejemplo"
    },
    {
        title: "Estilos en React",
        description: "Cuando trabajamos con React, muchas veces por cuestión de tiempo definimos nuestros componentes con estilos en línea, lo cual no es para nada recomendable, y se puede tornar en un problema a medida que nuestra aplicación crece. Veamos un ejemplo de esto"
    },
    {
        title: "",
        description: "Lo ideal para no incurrir en esta mala práctica es no definir los estilos en línea, y en su lugar usar módulos de CSS o librerías como styled-components. Veamos un ejemplo"
    },
    {
        title: "Renderizado de listas ",
        description: "Una mala práctica cuando se trabaja con React es al renderizar listas de elementos, no asignar una key única a cada elemento de la lista, esto puede causar problemas de rendimiento y comportamientos inesperados en nuestra aplicación, veamos un ejemplo"
    },
    {
        title: "",
        description: "Lo que podemos hacer es definir una key única a cada elemento de la lista, esto nos permitirá mejorar el rendimiento de nuestra aplicación y evitar comportamientos inesperados, veamos un ejemplo"
    },
    {
        title: "Que pasa cuando tenemos mucha lógica en nuestro componente?",
        description: "Lo que podemos hacer es dividir el componente en componentes más pequeños, esto nos permitirá mejorar el rendimiento de nuestra aplicación y evitar comportamientos inesperados, veamos un ejemplo"
    },
    {
        title: "",
        description: "Crear varios componentes pequeños puede ser una buena práctica, pero si tenemos muchos componentes pequeños, veamos un ejemplo"
    },
    {
        title: "If ternario en nuestro componente",
        description: "Tener if ternario en nuestro componente puede ser una mala práctica, ya que puede hacer que nuestro código sea difícil de leer y mantener, veamos un ejemplo"
    },
    {
        title: "",
        description: "Creamos una funcion de orden superario y con un objecto definimos el mensaje que queremos mostrar,luego programación defensiva para evitar errores"
    },
    {
        title: "Usar funciones inline en nuestro componente",
        description: "Tenemos que ser cuidadosos con las funciones inline en React."
    },
    {
        title: "",
        description: "Extraemos la funcion de nuestro return y la definimos en componente"
    },
    {
        title: "Mucha lógica en nuestro componente?",
        description: "Cuando tenemos mucha lógica en nuestro componente, suele ser dificil de leer y mantener, veamos un ejemplo"
    },
    {
        title: "",
        description: "Extraemos el if ternario y lo definimos con una mejor sintaxis en el componente"
    },
    {
        title: "Cómo mutar el estado directamente en el componente",
        description: "Lo correcto es no mutar el estado directamente, debemos usar la función de modificación que nos proporciona React, veamos un ejemplo"
    },
    {
        title: "",
        description: ""
    },
    {
        title: "El uso excesivo de useState",
        description: "El uso excesivo de useState puede causar problemas de rendimiento en nuestra aplicación, ya que cada vez que se actualiza el estado, se vuelve a renderizar el componente, lo cual puede afectar el rendimiento de nuestra aplicación"
    },
    {
        title: "",
        description: "Creamos un useState con cada estado que queremos manejar, TENER CUIDADO CON LOS COMPONENTES HIJJOS Y PADRES QUE MANEJAN ESTADO"
    },
    {
        title: "Repetis Fetch en tu componente?",
        description: "Si te paso esto en algun momento, donde usas el mismo fetch en diferentes componentes"
    },
    {
        title: "",
        description: "Crea un componente que maneje el fetch y luego con imports lo llamas en el componente"
    },
    {
        title: "No definas funciones en el componente funcional",
        description: "Intenta evitar definir funciones en el componente"
    },
    {
        title: "",
        description: "Crealo fuera como utils/helpers y luego lo importas en el componente, esto te va a dar escalabilidad y mantenimiento"
    },
    {
        title: "Definir constantes dentro del componente",
        description: "Una mala práctica es definir constantes dentro del componente, esto puede causar problemas de rendimiento ya que se están creando en cada renderizado"
    },
    {
        title: "",
        description: "Crear las constantes fuera del componente, esto hace que puedas manipular mejor cuando lo requieran"
    },
    {
        title: "Limpiar los efectos secundarios al desmontar el componente",
        description: "Una mala práctica es no limpiar los efectos secundarios al desmontar el componente, esto puede causar problemas de memoria y rendimiento en nuestra aplicación"
    },
    {
        title: "",
        description: "Lo correcto es limpiar los efectos secundarios al desmontar el componente, para que nuestro componente sea más fácil de leer y mantener, veamos un ejemplo"
    },
    {
        title: "Elementos innecesarios en el DOM",
        description: "Muchas veces solemos envolver varios elementos o componentes en un div para crear una estructura"
    },
    {
        title: "",
        description: "Lo correcto es crear <Fragment> para envolver los elementos o componentes, para que nuestro componente sea más fácil de leer y mantener, veamos un ejemplo"
    }
]