import { CodeBracketIcon  } from '@heroicons/react/24/outline';
import React from 'react';

const reviews = [
  { review: "Fue una clase muy enriquecedora, con mucha información que se super agradece. Quizás algo veloz en su dinámica para lo que veníamos trabajando. Voy a volver a verla, como hago con todas; de seguro le voy a sacar más jugo porque se aportó un montón." },
  { review: "Muy buena la clase, siempre con la atención a los alumnos." },
  { review: "Se nota que Cris toma en serio el feedback que le mandamos y acomoda la clase y cómo maneja los tiempos en base a eso. Muy valorable y se nota su esfuerzo." },
  { review: "Es un excelente profesor, hace la clase súper dinámica tanto para los que están recién comenzando como para los que tienen más conocimientos." },
  { review: "Ejemplos simples y claros. Me gusta el material adicional de consulta para profundizar en temas más generales de la programación." },
  { review: "El profe me parece 10 puntos, crack total. Además, es súper generoso en los conocimientos por fuera de lo que estamos viendo." },
];


const Reviews = () => {
  return (
    <div className="bg-gradient-to-r from-slate-500 to-slate-800" id="Testimonios">
      <div className="max-w-7xl mx-auto px-6 md:px-12">
        <div className="mb-10 text-center">
          <h1 className="text-4xl font-bold text-gray-800 dark:text-white md:text-6xl">
            La confianza de los <span class="text-lime-300">estudiantes</span>
          </h1>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {reviews.sort(() => Math.random() - 0.5).map((review, index) => (
            <div
              key={index}
              className="relative p-8 border border-gray-100 rounded-3xl bg-white dark:bg-gray-800 dark:border-gray-700 shadow-2xl shadow-gray-600/10 dark:shadow-none transform hover:scale-105 transition-transform"
              style={{
                transform: `translateY(${Math.random() * 30 - 15}px) translateX(${Math.random() * 30 - 15}px)`,
              }}
            >
              <div className="flex gap-4 items-center">
                <CodeBracketIcon  className="w-12 h-12 text-gray-700 dark:text-white" />
                <div>
                  <h2 className="text-lg font-medium text-gray-700 dark:text-white">{review.name}</h2>
                  <p className="text-sm text-gray-500 dark:text-gray-300">{review.role}</p>
                </div>
              </div>
              <p className="mt-8 text-gray-700 dark:text-gray-300">{review.review}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
