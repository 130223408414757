// Blog construido con React y Tailwind CSS
import React from 'react';

const Blog = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <div className="relative">
        <img 
          src="https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=2940&auto=format&fit=crop"
          alt="Gestión Comercial"
          className="w-full h-[400px] object-cover brightness-75"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
          <h1 className="text-4xl font-bold text-center">Preguntas Clave para Crear un CRM</h1>
          <p className="text-xl mt-4 max-w-2xl text-center">Un enfoque estratégico para cumplir con los objetivos del negocio.</p>
        </div>
      </div>

      <main className="max-w-4xl mx-auto mt-12">
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-700">1. Entendimiento del Negocio</h2>
          <ul className="list-disc list-inside mt-4 text-gray-600">
            <li><strong>¿Cuál es el principal objetivo de implementar un CRM?</strong> (Ej. organizar pedidos, mejorar la relación con los clientes, optimizar la operación interna).</li>
            <li><strong>¿Qué desafíos enfrentan actualmente que un CRM podría resolver?</strong></li>
            <li><strong>¿Cuáles son sus principales métricas de éxito?</strong> (Ej. aumento de ventas, reducción de errores, mayor satisfacción del cliente).</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-700">2. Gestión de Pedidos</h2>
          <ul className="list-disc list-inside mt-4 text-gray-600">
            <li><strong>¿Cómo reciben los pedidos actualmente? ¿Por qué canales?</strong> (Ej. WhatsApp, presencial, online).</li>
            <li><strong>¿Cuántos pedidos procesan en un día promedio y en un día pico?</strong></li>
            <li><strong>¿Cómo registran los pedidos en este momento?</strong> ¿Qué problemas han detectado en este proceso?</li>
            <li><strong>¿Qué nivel de personalización requieren los pedidos?</strong> (Ej. quitar ingredientes, añadir extras).</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-700">3. Flujo Operativo</h2>
          <ul className="list-disc list-inside mt-4 text-gray-600">
            <li><strong>¿Cuál es el flujo actual desde que se toma un pedido hasta que se entrega?</strong> ¿Quiénes participan en este proceso?</li>
            <li><strong>¿Cómo se comunican los pedidos a las diferentes áreas?</strong> (cocina, despacho)</li>
            <li><strong>¿Qué problemas enfrentan en la comunicación interna?</strong></li>
            <li><strong>¿Cuántos empleados utilizarán el CRM y qué roles cubrirán?</strong></li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-700">4. Clientes</h2>
          <ul className="list-disc list-inside mt-4 text-gray-600">
            <li><strong>¿Qué datos recopilan actualmente de los clientes?</strong> (Nombre, contacto, historial de pedidos)</li>
            <li><strong>¿Qué tan importante es ofrecer seguimiento personalizado o promociones?</strong></li>
            <li><strong>¿Les interesa automatizar mensajes</strong> como confirmaciones de pedido o solicitudes de reseñas?</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-700">5. Requerimientos Técnicos</h2>
          <ul className="list-disc list-inside mt-4 text-gray-600">
            <li><strong>¿Qué dispositivos utilizan actualmente?</strong> ¿Prefieren PC, tablets o terminales táctiles?</li>
            <li><strong>¿El CRM necesita funcionar en tiempo real?</strong> (Ej. actualizaciones instantáneas en la cocina)</li>
            <li><strong>¿Existen integraciones necesarias con otros sistemas?</strong> (Ej. sistemas de pago, facturación)</li>
            <li><strong>¿Prefieren una solución web, móvil, o ambas?</strong></li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-700">6. Reportes y Análisis</h2>
          <ul className="list-disc list-inside mt-4 text-gray-600">
            <li><strong>¿Qué tipo de reportes necesitan?</strong> (Ej. ventas diarias, tiempos de preparación, pedidos más vendidos)</li>
            <li><strong>¿Con qué frecuencia revisan estos reportes?</strong> (Diariamente, semanalmente, mensualmente)</li>
            <li><strong>¿Necesitan exportar los reportes a Excel o PDF?</strong></li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-700">7. Gestión de Inventario</h2>
          <ul className="list-disc list-inside mt-4 text-gray-600">
            <li><strong>¿Tienen un sistema actual para el control de inventario?</strong> Si no, ¿cómo gestionan los insumos?</li>
            <li><strong>¿Les interesa que el CRM registre automáticamente el uso de insumos por pedido?</strong></li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-700">8. Escalabilidad y Futuro</h2>
          <ul className="list-disc list-inside mt-4 text-gray-600">
            <li><strong>¿Tienen planes de expandirse a más sucursales?</strong> Si es así, ¿cómo debería escalar el CRM?</li>
            <li><strong>¿Qué otros procesos les gustaría digitalizar a futuro?</strong> (Ej. marketing, seguimiento de empleados)</li>
            <li><strong>¿Necesitarán soporte técnico continuo o capacitación para el personal?</strong></li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-700">9. Restricciones y Presupuesto</h2>
          <ul className="list-disc list-inside mt-4 text-gray-600">
            <li><strong>¿Qué restricciones técnicas enfrentan actualmente?</strong> (Ej. conexión a internet, dispositivos)</li>
            <li><strong>¿Tienen un presupuesto estimado para este proyecto?</strong></li>
            <li><strong>¿En cuánto tiempo esperan implementar el sistema?</strong></li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-700">10. Siguientes Pasos</h2>
          <ul className="list-disc list-inside mt-4 text-gray-600">
            <li><strong>¿Hay algún sistema CRM que hayan probado o que les guste como referencia?</strong></li>
            <li><strong>¿Quiénes serán los responsables internos de trabajar con nosotros en este proyecto?</strong></li>
            <li><strong>¿Cuál es la fecha ideal para presentar un primer prototipo o versión funcional?</strong></li>
          </ul>
        </section>
      </main>

      <footer className="text-center py-6 text-gray-600">
        <p>Consejo adicional: Asegúrate de tomar notas claras y ofrecer un resumen al final de la reunión con los puntos clave y los próximos pasos acordados. Esto reforzará la confianza del cliente en tu liderazgo técnico.</p>
      </footer>
    </div>
  );
};

export default Blog;
