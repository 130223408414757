import React from 'react';

const TextBlog = ( {title, description} ) => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">{title}</h1>
      <article className="prose lg:prose-xl">

        <div className="text-lg text-gray-700 leading-relaxed space-y-6">
          <p className="mb-4">
            {description}
          </p>
        </div>
      </article>
    </div>
  );
};

export default TextBlog;
