import React from 'react';
import CodeSnippet from './CodeSniped';
import { exampleCode, exampleCodeFetch, formCodeServer, formCodeServer2, useHookState, useHookState2, useBundleComponent, useBundleComponent2, useEffectComponent, useEffectComponent2, styleCode, styleCode2, mapCode, mapCode2, componentBigCode, componentBigCode2, ternaryCode, useTernaryCode, inlineCode, inlineCode2, logicComponentCode, logicComponentCode2, mutateStateCode, mutateStateCode2, useStateExcessCode, useStateExcessCode2, logicErrorCode, logicCodeHook, noFunctionComponent, noFunctionComponent2, defineConstantCode, defineConstantCode2, cleanEffectCode, cleanEffectCode2, elementInnecesarioCode, elementInnecesarioCode2 } from './utils/exampleCode';
import TextBlog from './textBlog';
import { arrayBlog } from './data';

const BlogPost = () => {
  const codeExamples = [exampleCode, exampleCodeFetch, formCodeServer, formCodeServer2, useHookState, useHookState2, useBundleComponent, useBundleComponent2, useEffectComponent, useEffectComponent2, styleCode, styleCode2, mapCode, mapCode2, componentBigCode, componentBigCode2, ternaryCode, useTernaryCode, inlineCode, inlineCode2, logicComponentCode,logicComponentCode2, mutateStateCode, mutateStateCode2, useStateExcessCode, useStateExcessCode2, logicErrorCode, logicCodeHook, noFunctionComponent, noFunctionComponent2, defineConstantCode, defineConstantCode2, cleanEffectCode, cleanEffectCode2, elementInnecesarioCode, elementInnecesarioCode2];

  return (
    <div className="max-w-4xl mx-auto px-4 py-8 mt-10">
      <h1 className="text-4xl font-bold text-gray-900 mb-4 text-center mt-10">Buenas prácticas para React</h1>
      <div className="shadow-lg rounded-lg">
        {arrayBlog.map((blog, index) => (
          <React.Fragment key={index}>
            <TextBlog 
              title={blog.title} 
              description={blog.description} 
              />
            <CodeSnippet 
              code={codeExamples[index]} 
              language="javascript" 
            />
          </React.Fragment>
        ))}
        </div>
    </div>
  );
};

export default BlogPost;
