import { Helmet } from 'react-helmet';
import SectionHeader from './components/sectionHeader';
import WhatsAppButton from './components/whatsApp';
import Blog from './components/blog'
import ProyectPortfolio from './components/ProyectPortfolio';
import Reviews from './components/Reviews';
import Navbar from './components/Navbar';
import CattegoryShow from './components/CattegoryShow';
import FeaturedResources from './components/CarouselService';
function Home() {
  return (
    <>
     <Helmet>
        <title> Tech Academy Courses</title>
        <meta name="description" content="Descubre nuestros cursos básicos de JavaScript diseñados para principiantes. Aprende de manera accesible y efectiva con lecciones prácticas. ¡Cursos de alta calidad a precios económicos!" />
      </Helmet>
      <Navbar />
      <SectionHeader />
      <FeaturedResources />
      <ProyectPortfolio />
      <CattegoryShow />
      <Reviews />
      <Blog /> 
      <WhatsAppButton />
    </>
  )
}

export default Home;
