import React from 'react';
import { Link } from 'react-router-dom';

// Definir un objeto de rutas dinámicas basado en el ID de los posts
const routes = {
  2: "/faq-entreview-junior/",
  3: "/faq-javascript-semis-senior/",
  4: "/web-design-tips/",
  5: "/article-component/",
  6: "/web-interactivity-design-trends/",
  7: "/difference-ssr-sc/",
  8: "/dom-optimize/",
  9: "/crm-questions-interview/",
  10: "/consejos.codigo-react/",
};

// Función para obtener la ruta correcta para cada post
const getPostLink = (post) => {
  return routes[post.id] || `/blog/${post.category.title}/`;
};

const posts = [
  {
    id: 10,
    title: 'Consejos para el codigo React',
    description:
      'Consejos para el codigo React',
    date: 'Dic 26, 2024',
    datetime: '26-12-2024',
    category: { title: 'Consejos' },
    author: {
      name: 'Developer',
    },
  },
  {
    id: 9,
    title: 'Preguntas clave para crear un CRM',
    description:
      'Un enfoque estratégico para cumplir con los objetivos del negocio.',
    date: 'Dic 18, 2024',
    datetime: '18-12-2024',
    category: { title: 'Lectura entretenida' },
    author: {
      name: 'Gerente Comercial',
    },
  },
  {
    id: 8,
    title: 'Optimización del DOM ',
    description:
      'Mejora el rendimiento de tu aplicación',
    date: 'Dic 15, 2024',
    datetime: '15-12-2024',
    category: { title: 'Lectura entretenida' },
    author: {
      name: 'Developer',
    },
  },
  {
    id: 7,
    title: 'Diferencia entre SSR y SC',
    description:
      'Cuando usar cada uno',
    date: 'Dic 13, 2024',
    datetime: '13-12-2024',
    category: { title: 'Lectura entretenida' },
    author: {
      name: 'Developer',
    },
  },
  {
    id: 6,
    title: 'Tendencias de Interactividad en Diseño Web ',
    description:
      'Los estilos mas usados en 2024',
    date: 'Nov 14, 2024',
    datetime: '14-11-2024',
    category: { title: 'Lectura entretenida' },
    author: {
      name: 'Developer',
    },
  },
  {
    id: 5,
    title: 'Frontend con UX/UI',
    description:
      '6 Ventajas de tener conocimientos en UI/UX',
    date: 'Oct 27, 2024',
    datetime: '27-10-2024',
    category: { title: 'Lectura entretenida' },
    author: {
      name: 'Desing',
    },
  },
  {
    id: 4,
    title: 'Tips de codigo',
    description:
      'Consejos a la hora de diseñar paginas web',
    date: 'Oct 10, 2024',
    datetime: '10-10-2024',
    category: { title: 'Consejos' },
    author: {
      name: 'Developer',
    },
  },
  {
    id: 3,
    title: 'Preguntas entrevista Semis Senior',
    description:
      'Programadores Javascript',
    date: 'Oct 02, 2024',
    datetime: '02-10-2024',
    category: { title: 'Pregunta Entrevista' },
    author: {
      name: 'Developer',
    },
  },
  {
    id: 2,
    title: 'Preguntas entrevista Junior',
    description:
      'Programadores Javascript',
    date: 'Ago 03, 2024',
    datetime: '03-08-2024',
    category: { title: 'Pregunta Entrevista' },
    author: {
      name: 'Developer',
    },
  },
  {
    id: 1,
    title: 'La IA no saca trabajo?',
    description:
      'La respuesta los sorprenderá',
    date: 'Feb 19, 2024',
    datetime: '19-02-2024',
    category: { title: 'Programacion' },
    author: {
      name: 'IA',
    },
  }
  
];

export default function Blog() {
  return (
    <div className="bg-gradient-to-r from-slate-500 to-slate-800 py-12 sm:py-16 md:py-24" id="Blog">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-black">
            Mira nuestro Blog
          </h2>
          <p className="mt-2 text-base sm:text-lg font-bold leading-8 text-lime-400">
            Fíjate lo que opinan nuestros expertos sobre temáticas cotidianas
          </p>
        </div>

        <div className="mt-6 sm:mt-8 lg:mt-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {posts.map((post) => (
              <article key={post.id}>
                <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-3 sm:p-5 h-full transition-transform duration-300 ease-in-out hover:shadow-xl hover:-translate-y-2">
                  <div className="flex items-center gap-x-2 sm:gap-x-4 text-xs text-gray-500">
                    <time dateTime={post.datetime} className="text-gray-500">
                      {post.date}
                    </time>
                    <p 
                    className="relative z-10 rounded-full bg-slate-200 text-slate-600 px-2 sm:px-3 py-1 sm:py-1.5 font-medium ">
                      {post.category.title}
                    </p>
                  </div>
                  <div className="group relative mt-3 sm:mt-4">
                    <h3 className="text-base sm:text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-700 dark:text-gray-100 dark:group-hover:text-gray-300">
                      <Link 
                        target='_blank'
                        to={getPostLink(post)}  
                        rel='noreferrer'
                      >
                        <span className="absolute inset-0" />
                        {post.title}
                      </Link>
                    </h3>
                    <p className="mt-2 sm:mt-3 line-clamp-3 text-xs sm:text-sm leading-5 sm:leading-6 text-gray-600 dark:text-gray-400">
                      {post.description}
                    </p>
                  </div>
                  <div className="relative mt-4 sm:mt-8 flex items-center gap-x-2 sm:gap-x-4">
                    <div className="text-xs sm:text-sm leading-5 sm:leading-6">
                      <p className="font-semibold text-gray-900 dark:text-gray-100">
                        {post.author.name}
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}